<template>
  <div id="ServeInfo">
    <TopBar class="background">
      <template #leftInfo>
        <div style="white-space: nowrap" @click="goLastIndex">
          <svg t="1601170867949" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="4264" width="24" height="24">
            <path d="M608.992 148.992L277.984 489.984q-8.992 8.992-8.992 21.504t8.992 22.496l331.008 340.992q8.992 8 20.992 8t20.992-8.992 8.992-20.992-8.992-20.992l-312-320 312-320q8.992-8.992 8.992-20.992t-8.992-20.992-20.992-8.992-20.992 8z" p-id="4265" fill="#ffffff"></path>
          </svg>
        </div>
      </template>
      <template #middleInfo> <span class="topTitle">服务信息</span></template>
      <template #rightInfo> </template>
    </TopBar>
    <div class="baceInfoWrap">
      <div class="baceInfoPublic">
        <p class="tip">资料的真实性直接影响着商家对你的信任</p>
        <van-cell-group>
          <van-cell title="服务类目" :value="serviceMenu" is-link @click="serviceShow = true,stateTemp = true" />
          <van-popup v-model="serviceShow" position="bottom" :style="{ height: '80%' }">
            <tool-bar @cancel="serviceShow = false" @confirm="reviewServiceMenu" leftTitle="取消" rightTitle="确定" title="选择服务类目" />
            <van-collapse v-model="activeName">
              <van-collapse-item title="主要服务类目(至多一个）" name="1">
                <ServiceType :copyServiceObj="copyServiceObj_main" selectNum="1" key1="1" title="选择主服务类目(至多一个）" :temp="disabled" @activeArrFalse="activeArrFalse1" @activeArrTrue="activeArrTrue1" />
              </van-collapse-item>
              <van-collapse-item title="附加服务类目" name="2">
                <div style="margin-bottom: 80px">
                  <ServiceType :copyServiceObj="copyServiceObj_add" selectNum="16" key1="2" title="选择附加服务类目" :temp="disabled" @activeArrTrue2="activeArrTrue2" @activeArrFalse2="activeArrFalse2" />
                </div>
              </van-collapse-item>
              <!-- <div style="position: fixed; bottom: 0; left: 0; right: 0;z-index:99">
                <van-button color="linear-gradient(to right, #ff6034, #ee0a24)" block @click="reviewServiceMenu">
                  确定
                </van-button>
              </div> -->
            </van-collapse>
          </van-popup>
          <van-cell title="服务类型" is-link :value="serviceTypeInfo" @click="serviceTypeShow = true" />
          <van-popup v-model="serviceTypeShow" position="bottom" :style="{ height: '80%' }">
            <tool-bar @cancel="serviceTypeShow = false" @confirm="serviceTypeRight" leftTitle="取消" rightTitle="确定" title="服务类型" />
            <div class="propWrap">
              <van-checkbox-group v-model="result" ref="checkboxGroup">
                <van-cell-group>
                  <van-cell v-for="(item, index) in list" clickable :key="index" :title="`${item.type_name}`" @click="toggle(index)">
                    <template #right-icon>
                      <van-checkbox :name="item" ref="checkboxes" checked-color="#FF816C" />
                    </template>
                  </van-cell>
                </van-cell-group>
              </van-checkbox-group>
            </div>
          </van-popup>
          <van-cell title="服务区域" :value="selectAdressObj.adressInfo" is-link @click="selectAdressObj.serviceAdressShow = true" />
          <van-popup v-model="selectAdressObj.serviceAdressShow" position="bottom" :style="{ height: '80%' }">
            <SelectAdress @getEmitObj="getEmitObj" :modelEmitObj="modelEmitObj" />
          </van-popup>
          <van-cell title="工作日" :value="selectWeekDayObj.weekDayInfo" is-link @click="selectWeekDayObj.weekDayShow = true" />
          <van-popup v-model="selectWeekDayObj.weekDayShow" position="bottom" :style="{ height: '60%' }">
            <selectPicker :columns="selectWeekDayObj.weekDayColumns" pickerTitle="请选择工作日" @pickerResult="getConfirmPickerObj" :needLine="true" @cancel="selectWeekDayObj.weekDayShow = false" />
          </van-popup>
          <van-cell title="时间段" :value="selectTimerObj.timerInfo" is-link @click="selectTimerObj.timerShow = true" />
          <van-popup v-model="selectTimerObj.timerShow" position="bottom" :style="{ height: '60%' }">
            <selectPicker default-index="1" :columns="selectTimerObj.timerColumns" pickerTitle="请选择工作时间段" @pickerResult="getTimerResultObj" :needLine="true" @cancel="selectTimerObj.timerShow = false" />
          </van-popup>
          <van-cell title="团队人数">
            <template #right-icon>
              <div class="teamPeople">
                <input type="number" v-model="teamNum">
                <span>人</span>
              </div>
            </template>
          </van-cell>
          <van-cell title="货车数量">
            <template #right-icon>
              <div class="teamPeople">
                <input type="number" v-model="truck_number">
                <span>辆</span>
              </div>
            </template>
          </van-cell>
          <van-cell title="货车类型" :value="truckTypeObject.truckColumns[0].values[this.truckTypeObject.truckTypeInfo]" is-link @click="truckTypeObject.truckShow = true" v-show="truck_number > 0" />
          <van-popup v-model="truckTypeObject.truckShow" position="bottom" :style="{ height: '80%' }">
            <selectPicker :columns="truckTypeObject.truckColumns" pickerTitle="请选择汽车类型" @pickerResult="getTruckResultObj" @cancel="truckTypeObject.truckShow = false" />
          </van-popup>
          <van-cell title="货车吨位" is-link v-show="truck_number > 0">
            <template #right-icon>
              <div class="teamPeople">
                <input type="number" v-model="truck_tonnage">
                <span>吨</span>
              </div>
            </template>
          </van-cell>
        </van-cell-group>
      </div>
    </div>
    <div class="baceInfoWrap del">
      <div class="baceInfoPublic">
        <van-cell-group>
          <van-field v-model="pick_up_point" rows="1" autosize label="期望收货点" type="textarea" placeholder="请填写期望收货点" />
          <van-field v-model="description" rows="2" autosize label="优势简介" type="textarea" maxlength="100" placeholder="请填写个人介绍" show-word-limit />
        </van-cell-group>
      </div>
    </div>
    <div style="position: fixed; bottom: 0; left: 0; right: 0;z-index:99">
      <van-button color="linear-gradient(to right, #ff6034, #ee0a24)" block @click="reviewService">
        确定提交
      </van-button>
    </div>
  </div>
</template>
<script>
import TopBar from "@/components/home/TopBar.vue";
import ServiceType from "@/components/Mine/baseInfo/ServiceType.vue";
import ServiceMenu from "@/components/Mine/baseInfo/ServiceMenu.vue";
import selectPicker from "@/components/Mine/baseInfo/selectPicker.vue";
import SelectAdress from "@/components/effect/SelectAdress.vue";
import ToolBar from "@/components/effect/ToolBar.vue";
export default {
  data() {
    return {
      getServeInfo: {},
      serviceMenu: "选择服务类目",
      service_categories: [],
      activeName: ["1"],
      picShow: false,
      sexShow: false,
      phoneShow: false,
      temp: false,
      radio: 1,
      serviceTypeShow: false,
      serviceTypeInfo: "选择服务类型",
      serviceShow: false,
      serviceObj: [],
      copyServiceObj_main: [],
      copyServiceObj_add: [],
      mainServiceObj: {},
      addServiceObj: {},
      disabled: true,
      newResult: [],
      result: [],
      list: [],
      modelEmitObj: {},
      selectAdressObj: {
        serviceAdressShow: false,
        serviceAdressInfo: [],
        adressInfo: "请选择服务区域",
      },
      selectWeekDayObj: {
        weekDayArray: [],
        weekDayInfo: "请选择工作日",
        weekDayShow: false,
        weekDayColumns: [
          // 第一列
          {
            values: ["周一", "周二", "周三", "周四", "周五", "周六", "周日"],
            defaultIndex: 0,
          },
          // 第二列
          {
            values: ["周一", "周二", "周三", "周四", "周五", "周六", "周日"],
            defaultIndex: 4,
          },
        ],
      },
      selectTimerObj: {
        timerArray: [],
        timerInfo: "请选择工作时间段",
        timerShow: false,
        timerColumns: [
          // 第一列
          {
            values: [
              "00:00",
              "01:00",
              "02:00",
              "03:00",
              "04:00",
              "05:00",
              "06:00",
              "07:00",
              "08:00",
              "09:00",
              "10:00",
              "11:00",
              "12:00",
              "13:00",
              "14:00",
              "15:00",
              "16:00",
              "17:00",
              "18:00",
              "19:00",
              "20:00",
              "21:00",
              "22:00",
              "23:00",
              "24:00",
            ],
            defaultIndex: 7,
          },
          // 第二列
          {
            values: [
              "00:00",
              "01:00",
              "02:00",
              "03:00",
              "04:00",
              "05:00",
              "06:00",
              "07:00",
              "08:00",
              "09:00",
              "10:00",
              "11:00",
              "12:00",
              "13:00",
              "14:00",
              "15:00",
              "16:00",
              "17:00",
              "18:00",
              "19:00",
              "20:00",
              "21:00",
              "22:00",
              "23:00",
              "24:00",
            ],
            defaultIndex: 18,
          },
        ],
      },
      activeArrA: [],
      activeArrB: [],
      teamNum: "",
      truck_number: 0,
      truck_tonnage: "",
      pick_up_point: "",
      description: "",
      truckTypeObject: {
        truckTypeArr: [],
        truckTypeInfo: "",
        truckShow: false,
        truckColumns: [
          {
            values: ["小型面包", "中型面包", "小型货车", "中型货车", "三轮车"],
            defaultIndex: 1,
          },
        ],
      },
    };
  },
  components: {
    ToolBar,
    TopBar,
    ServiceMenu,
    ServiceType,
    SelectAdress,
    selectPicker,
  },
  created() {
    // get服务信息
    this.$http.get("/api/v1/worker/service").then((res) => {
      console.log(res);
      // get服务类目
      let _serviceMenu = [];
      // res.data.service_categories
      this.service_categories = [];
      res.data.service_categories.reverse().forEach((item, index) => {
        _serviceMenu.push(item.type_name);
        this.service_categories.push(item.id);
      });
      this.serviceMenu = _serviceMenu.join(",");
      // get服务类型
      let _serviceTypeInfo = [];
      res.data.service_modes.forEach((item, index) => {
        _serviceTypeInfo.push(item.type_name);
        this.newResult.push(item.id);
      });
      this.serviceTypeInfo = _serviceTypeInfo.join(",");
      // get服务区域
      let _adressInfo = [];
      res.data.service_areas.forEach((item, index) => {
        _adressInfo.push(item.name);
        this.selectAdressObj.serviceAdressInfo.push(item.code);
      });
      this.selectAdressObj.adressInfo = _adressInfo.join(",");
      // get工作日
      this.selectWeekDayObj.weekDayInfo = res.data.working_day
        ? res.data.working_day.replace(",", "至")
        : "";

      this.selectWeekDayObj.weekDayArray = res.data.working_day
        ? res.data.working_day.split(",")
        : "";
      // 选择时间段
      this.selectTimerObj.timerInfo = res.data.service_period
        ? res.data.service_period.replace(",", "至")
        : "";

      this.selectTimerObj.timerArray = res.data.service_period
        ? res.data.service_period.split(",")
        : "";
      // 团队人数
      this.teamNum = res.data.employee;
      // 汽车数量
      this.truck_number = res.data.truck_number;
      //汽车类型
      this.truckTypeObject.truckTypeInfo = res.data.truck_type;
      //汽车吨位
      this.truck_tonnage = res.data.truck_tonnage;
      //个人介绍
      this.description = res.data.description;
      //收货点
      this.pick_up_point = res.data.pick_up_point;
    });
    this.$ajax
      .get("/api/common/firstCategory", {})
      .then((res) => {
        console.log("原始分类数据", res);
        res.data.forEach((item) => {
          item["datHave"] = false;
        });
        this.serviceObj = res.data;
        this.copyServiceObj_main = JSON.parse(JSON.stringify(this.serviceObj));
        this.copyServiceObj_add = JSON.parse(JSON.stringify(this.serviceObj));
      });
    // 服务类型接口获取
    this.$ajax
      .get(`/api/common/serviceMode?id=1`, {})
      .then((res) => {
        this.list = res.data;
      });
  },
  methods: {
    activeArrTrue1(e) {
      this.activeArrA = e;
      console.log("主分类选中的列表", this.activeArrA);
      this.copyServiceObj_add.forEach((item, index) => {
        this.activeArrA.forEach((el, i) => {
          if (item.id == el.id) {
            this.copyServiceObj_add.remove(item);
          }
        });
      });
      this.activeArrA.forEach((item, index) => {
        this.activeArrB.forEach((el, i) => {
          if (item.id == el.id) {
            this.activeArrB.remove(el);
          }
        });
      });
    },
    activeArrFalse1(e) {
      this.copyServiceObj_add.push(e);
      // 重置看需要
      // this.copyServiceObj_add.forEach((item,index) => {
      //   item.datHave = false
      //   item.flag = undefined
      // })
    },
    activeArrTrue2(e) {
      this.activeArrB = e;
      console.log("次分类选中的列表", this.activeArrB);
    },
    activeArrFalse2(e) {
      console.log("次分类选中的列表", this.activeArrB);
    },
    reviewServiceMenu() {
      if (this.activeArrA.length != 0 && this.activeArrB.length != 0) {
        let _allArr = this.activeArrA.concat(this.activeArrB);
        let _allArrName = [];
        let _allArrId = [];
        console.log("合起来的类目", _allArr);
        _allArr.forEach((item, index) => {
          _allArrName.push(item.type_name);
          _allArrId.push(item.id);
        });
        this.service_categories = _allArrId;
        this.serviceMenu = _allArrName.join(",");
        this.serviceShow = false;
      } else {
        this.$toast("请完善分类信息");
      }
    },
    onClickRight(res) {
      // console.log(this.serviceInfoArr, "选中的分类数组");
      if (this.serviceInfoArr.length > 0) {
        this.serviceInfo = this.serviceInfoArr.join(",");
        this.serviceShow = false;
      } else {
        this.serviceInfo = "选择服务类目 >";
        this.serviceShow = false;
      }
    },
    serviceTypeRight() {
      let resultList = [];
      let _resultListId = [];
      this.result.forEach((item, index) => {
        resultList.push(item.type_name);
        _resultListId.push(item.id);
      });
      this.serviceTypeInfo = resultList.join(",");
      this.newResult = _resultListId;
      this.serviceTypeShow = false;
      console.log("服务类型ID", this.newResult);
    },
    toggle(index) {
      this.$refs.checkboxes[index].toggle();
    },
    checkAll() {
      this.$refs.checkboxGroup.toggleAll(true);
    },
    close() {
      this.phoneShow = false;
    },
    changePhone() {
      console.log("无数据");
    },
    setSex(index) {
      this.radio = index;
      this.sexShow = false;
    },
    goLastIndex() {
      this.$router.go(-1);
    },
    afterRead(file) {
      console.log(file);
    },
    confirm() {},
    getConfirmPickerObj(e) {
      console.log("工作日", e);
      this.selectWeekDayObj.weekDayInfo =
        e.pickerResult[0] + "至" + e.pickerResult[1];
      this.selectWeekDayObj.weekDayShow = e.pickerShow;
      this.selectWeekDayObj.weekDayArray = e.pickerResult;
    },
    getTimerResultObj(e) {
      this.selectTimerObj.timerInfo =
        e.pickerResult[0] + "至" + e.pickerResult[1];
      this.selectTimerObj.timerShow = e.pickerShow;
      this.selectTimerObj.timerArray = e.pickerResult;
    },
    getTruckResultObj(e) {
      this.truckTypeObject.truckTypeInfo = this.truckTypeObject.truckColumns[0].values.indexOf(
        e.pickerResult[0]
      );
      console.log(this.truckTypeObject.truckTypeInfo);

      this.truckTypeObject.truckShow = e.pickerShow;
    },
    getEmitObj(e) {
      console.log("更改地址", e);
      let _arr = [];
      let _arrCode = [];
      e.result.forEach((res) => {
        _arr.push(res.name);
        _arrCode.push(res.code);
      });
      this.selectAdressObj.adressInfo = _arr.join(",");
      this.selectAdressObj.serviceAdressInfo = _arrCode;
      this.selectAdressObj.serviceAdressShow = e.close;
    },
    reviewService() {
      this.$http
        .post("/api/worker/serviceInfo", {
          service_categories: this.service_categories.join(","),
          service_modes: this.newResult.join(","),
          service_areas: this.selectAdressObj.serviceAdressInfo.join(","),
          working_day: this.selectWeekDayObj.weekDayArray.join(","),
          service_period: this.selectTimerObj.timerArray.join(","),
          employee: Number(this.teamNum),
          truck_number: this.truck_number,
          truck_type: this.truckTypeObject.truckTypeInfo,
          truck_tonnage: this.truck_tonnage,
          description: this.description,
          pick_up_point: this.pick_up_point,
        })
        .then((res) => {
          this.$toast.success("修改成功");
          this.$router.go(0);
          console.log(res);
        });
    },
  },
};
</script>
<style lang="less">
#ServeInfo {
  .van-cell__value {
    span {
      font-size: 1.3rem;
    }
  }
  .baceInfoWrap {
    &.del {
      margin: 0;
      margin-bottom: 70px;
    }
    .tip {
      font-size: 1.4rem;
      text-align: center;
      padding: 1rem 0;
    }
    margin-top: 45px;
    .baceInfoPublic {
      margin: 1rem 0;
    }
  }
  .upLoadImg {
    width: 100%;
    height: 5rem;
    background: darkcyan;
  }
  .urgentPhone {
    width: 40%;
    border: none;
    text-align: left;
  }
  .urgentCode {
    text-align: left;
    width: 40%;
    border: none;
  }
  .van-nav-bar__text {
    color: #000;
  }
  .van-picker__confirm {
    color: #000;
  }
  .fontWrap {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    font-size: 1.4rem;
    width: 40%;
    text-align: right;
  }
  .propWrap {
    margin-bottom: 55px;
  }

  .teamPeople {
    color: #9a9799;
    input {
      text-align: center;
      width: 5rem;
      border: none;
    }
  }
}
</style>