<template>
  <div id="selectTimer">
    <van-picker show-toolbar :title="pickerTitle" :columns="columns" @confirm="confirmPicker" @cancel="cancel">
    </van-picker>
    <span class="line" v-if="needLine">至</span>
  </div>
</template>
<script>
export default {
  props: {
    needLine: {
      type: Boolean,
      default: false,
    },
    columns: {
      type: Array,
      default() {
        return [];
      },
    },
    pickerTitle: {
      type: String,
      default: "",
    },
  },
  data() {
    return {};
  },
  methods: {
    confirmPicker(e) {
      console.log("组件-选择器内容", e);
      var confirmPickerObj = {
        pickerResult: e,
        pickerShow: false,
      };
      this.$emit("pickerResult", confirmPickerObj);
    },
    cancel() {
      this.$emit("cancel");
    },
  },
};
</script>
<style lang="less" scoped>
#selectTimer {
  position: relative;
  .line {
    width: 5rem;
    height: 5rem;
    font-size: 1.4rem;
    // font-weight: 600;
    color: red;
    text-align: center;
    line-height: 5rem;
    position: absolute;
    top: calc(49%);
    left: calc(50% - 2.5rem);
    z-index: 1;
  }
}
</style>