<template>
  <div class="serviceType">
    <!-- left-text="重选" @click-left="onClickLeft" -->
    <van-grid :gutter="10" clickable>
      <van-grid-item icon="photo-o" :text="item.type_name" v-for="(item, index) in copyServiceObj" :key="index" @click="selectCategory(index)" :class="item.datHave ? 'avan-grid-item__text' : ''" />
      <div style="position: fixed; bottom: 0; left: 0; right: 0">
      </div>
    </van-grid>
  </div>
</template>
<script>
export default {
  props: {
    copyServiceObj: {
      type: Array,
      default() {
        return [];
      },
    },
    selectNum: {
      type: String,
      default: "0",
    },
    key1: {
      type: String,
    },
    title: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      activeArr1: [],
      activeArr2: [],
      serviceInfoArr: [],
      serviceIdArr: [],
      serviceArr: [],
      serviceObject: {},
    };
  },
  created() {},
  watch: {},
  methods: {
    selectCategory(index) {
      // 选中之后,请求对应类目的服务类型
      switch (this.key1) {
        case "1":
          if (
            this.copyServiceObj[index].flag == true ||
            this.copyServiceObj[index].flag == undefined
          ) {
            // 选中的时候
            let _serverLength = this.activeArr1.length;
            if (_serverLength >= 0 && _serverLength < this.selectNum) {
              this.copyServiceObj[index].datHave = true;
              console.log(this.copyServiceObj);
              this.activeArr1.push(this.copyServiceObj[index]);
              this.$emit("activeArrTrue", this.activeArr1);
              this.copyServiceObj[index].flag = false;
            } else {
              this.$toast("主类目至多选一个");
            }
          } else {
            // 取消的时候
            this.copyServiceObj[index].datHave = false;
            this.activeArr1.remove(this.copyServiceObj[index]);
            this.$emit("activeArrFalse", this.copyServiceObj[index]);
            this.copyServiceObj[index].flag = true;
          }
          break;
        case "2":
          if (
            this.copyServiceObj[index].flag == true ||
            this.copyServiceObj[index].flag == undefined
          ) {
            this.copyServiceObj[index].datHave = true;
            this.activeArr2.push(this.copyServiceObj[index]);
            this.$emit("activeArrTrue2", this.activeArr2);
            this.copyServiceObj[index].flag = false;
          } else {
            this.copyServiceObj[index].datHave = false;
            this.activeArr2.remove(this.copyServiceObj[index]);
            this.$emit("activeArrFalse2", this.copyServiceObj[index]);
            this.copyServiceObj[index].flag = true;
          }
          break;
      }
    },
  },
};
</script>
<style lang="less" scoped>
.avan-grid-item__text {
  color: red;
  font-weight: bold;
}
</style>